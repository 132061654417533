#hs-chat-element-custom #hubspot-conversations-inline-parent,
#hs-chat-element-custom
  #hubspot-conversations-inline-parent
  #hubspot-conversations-inline-iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

[data-isfocus='true'] .reading-key-btn {
  position: relative;
}
[data-isfocus='true'] .reading-key-btn:not(:hover)::after {
  content: '';
  position: absolute;
  inset: 0;
  z-index: inherit;
  border-radius: 9999px;
  overflow: hidden;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
  pointer-events: none;
  opacity: 0.75;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
